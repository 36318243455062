import React, { useEffect, useState, useMemo } from 'react';
import T from 'prop-types';
// components
import CenterBox from 'components/CenterBox';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ReactSelect from 'components/Select/ReactSelect';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
import Button from 'components/Button';
import CommentBox from 'components/CommentBox';
import RangeInput from 'components/RangeInput/RangeInput';
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import cn from 'classnames';
import moment from 'moment';
import { animateScroll } from 'react-scroll';
import { convertLbsToTons } from 'utils/weightHelper';
import { formatNumber } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import './FeedOrderForm.scss';
import { withRouter } from 'react-router';

const defaultBin = { id: null, bin_id: null, quantity: 0 };

const FeedOrderForm = ({
  initialValues,
  onSubmit,
  binsOptions,
  backLink,
  title,
  submitLabel,
  farmName,
  groupName,
  router,
}) => {
  const [notes, setNotes] = useState(initialValues.notes || '');
  const [feedOrderBins, setFeedOrderBins] = useState(initialValues.feed_order_bins_attributes?.length
    ? initialValues.feed_order_bins_attributes.map((b) => ({ ...b }))
    : [defaultBin]);
  const [destroyedBins, setDestroyedBins] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(initialValues.delivery_date || null);
  const [isLoading, setIsLoading] = useState(false);

  const onNotesOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  const totalOrderQuantity = feedOrderBins.reduce((acc, bin) => acc + (bin.quantity || 0), 0);

  const isContinueDisabled = () => {
    if (!deliveryDate) return true;
    if (feedOrderBins.some(({ bin_id, quantity }) => !bin_id || !quantity || quantity <= 0)) return true;
    return false;
  };

  const removeBin = (index) => {
    setFeedOrderBins((prevBins) => {
      const binToRemove = prevBins[index];
      const newBins = [...prevBins.slice(0, index), ...prevBins.slice(index + 1)];
      if (binToRemove.id) {
        setDestroyedBins((prevDestroyedBins) => [...prevDestroyedBins, { ...binToRemove, _destroy: true }]);
      }
      return newBins;
    });
  };

  const onBinSelect = (index) => ({ id }) => {
    const selectedBin = binsOptions.find((bOption) => bOption.id === id);
    const newBinCapacity = selectedBin?.capacity || 30000;

    setFeedOrderBins((prev) => {
      const currentQuantity = prev[index].quantity;
      const clampedQuantity =
        currentQuantity > newBinCapacity ? newBinCapacity : currentQuantity;

      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          bin_id: id,
          quantity: clampedQuantity,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const onBinAdd = () => setFeedOrderBins((prevBins) => [...prevBins, defaultBin]);

  const setBinData = (index) => (key, value) => {
    setFeedOrderBins((prevState) => [
      ...prevState.slice(0, index),
      { ...prevState[index], [key]: value },
      ...prevState.slice(index + 1)
    ]);
  };

  const hideTrash = (feedOrderBins.length === 1 && !feedOrderBins[0].id);

  const submitData = async () => {
    if (isContinueDisabled()) return;

    try {
      setIsLoading(true);
      const allBins = [...feedOrderBins, ...destroyedBins];
      const resource = {
        delivery_date: deliveryDate ? deliveryDate.format('YYYY-MM-DD') : null,
        notes,
        feed_order_bins_attributes: allBins.map((bin) => ({
          id: bin.id,
          bin_id: bin.bin_id,
          quantity: bin.quantity,
          _destroy: bin._destroy
        })),
      };
      await onSubmit(resource);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const TotalQuantityLine = ({ value, mobile, className }) => {
    return (
      <div className={cn(`total-quantity-line ${className}`, { 'mobile': mobile })}>
        <span className="left-value">
          <span className="icon fa fa-ep-piggy" />
          <FormattedMessage id="general.totalOrder" />
        </span>
        <div className="quantity-container">
          <div className="quantity-lbs">
            <strong>
              {formatNumber(value)}&nbsp;
              <FormattedMessage id="general.weight.lbs" />
            </strong>
          </div>
          <div className="quantity-tons">
            <small>
              (<FormattedMessage
                id="general.tonsValue"
                values={{ value: formatNumber(convertLbsToTons(value).toFixed(1)) }}
              />)
            </small>
          </div>
        </div>
      </div>
    );
  };

  const binsOptionsWithCapacity = useMemo(() => {
    return binsOptions.map((b) => ({
      ...b,
      name: b.capacity ? `${b.name} (${formatNumber(b.capacity)})` : b.name,
    }));
  }, [binsOptions]);

  return (
    <CenterBox
      className="DailyCheckupOrderFeed"
      isLoading={false}
      renderIf
    >
      <StickyHeader className="hide-for-large">
        <TotalQuantityLine mobile value={totalOrderQuantity} />
      </StickyHeader>

      <section className="center-box-header show-for-large">
        <div>
          {farmName && <div className="subtitle">{farmName}</div>}
          <h1 className="title">
            {title || <FormattedMessage id="general.feedOrder" />}
          </h1>
        </div>
        <Link to={backLink}>
          <i className="fa fa-times close-center-box" />
        </Link>
      </section>

      <TotalQuantityLine mobile={false} value={totalOrderQuantity} className="show-for-large" />

      <section className="center-box-body">
        <div className="migrations-form">
          {feedOrderBins.map((bin, index) => {
            const selectedBin = binsOptions.find((bOption) => bOption.id === bin.bin_id);
            const maxCapacity = selectedBin?.capacity || 30000;

            return (
              <div className="block" key={`bin-${index}`}>
                <div className="input-row">
                  <div className="index-cell">
                    <div className="row-index">{index + 1}</div>
                  </div>
                  <div className="input-group">
                    <ReactSelect
                      filterOptions={(options) => {
                        const selectedBinIds = feedOrderBins
                          .filter((_, i) => i !== index)
                          .map((b) => b.bin_id);
                        return options.filter((option) => !selectedBinIds.includes(option.id));
                      }}
                      className="select-wrapper"
                      placeholder={<FormattedMessage id="general.selectBin" />}
                      options={binsOptionsWithCapacity}
                      valueKey="id"
                      labelKey="name"
                      value={bin.bin_id}
                      onChange={onBinSelect(index)}
                      noInputMargin
                      searchable={false}
                    />
                    <div className="input-group__item range-input">
                      <span className="input-label-group">
                        <span className="input-label"><FormattedMessage id="general.orderQuantity" /></span>
                        <span className="bin-quantity-label">
                          <div className="bin-quantity-lbs">
                            <b>
                              {formatNumber(bin.quantity || 0)}&nbsp;
                              <FormattedMessage id="general.weight.lbs" />
                            </b>
                          </div>
                          <div className="bin-quantity-tons">
                            <small>
                              (<FormattedMessage
                                id="general.tonsValue"
                                values={{ value: formatNumber(convertLbsToTons(bin.quantity || 0).toFixed(1)) }}
                              />)
                            </small>
                          </div>
                        </span>
                      </span>
                      <div className="range-container">
                        <RangeInput
                          step={1000}
                          max={maxCapacity}
                          label={<FormattedMessage id="general.weight.lbs" />}
                          value={bin.quantity || 0}
                          onChange={(value) => setBinData(index)('quantity', value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={cn('trash-cell', { 'hidden': hideTrash })}>
                    {!hideTrash && (
                      <i className="fa fa-trash-o trash-icon" onClick={() => removeBin(index)} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button className="add-bin-button" onClick={onBinAdd}>
          <FormattedMessage id="general.addAnotherBin" />
        </button>
        <div className="input-group__item" style={{ marginTop: '20px' }}>
          <span className="input-label"><FormattedMessage id="general.deliveryDate" /></span>
          <SingleDatePicker
            inputId="deliveryDateInput"
            key={`deliveryDate-${deliveryDate ? deliveryDate.format('YYYY-MM-DD') : 'null'}`}
            className="date-input"
            date={deliveryDate}
            onDateChange={setDeliveryDate}
            inputLabel="MM/DD/YYYY"
            isOutsideRange={(day) => !(day.isSameOrAfter(moment(), 'day'))}
          />
        </div>
        <CommentBox
          onOpen={onNotesOpen}
          onValueChange={(newNotes) => setNotes(newNotes)}
          value={notes}
          isOpen={!!notes}
        />
      </section>

      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" to={backLink}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button
            primary
            disabled={isContinueDisabled()}
            className="submit wider"
            onClick={submitData}
          >
            {submitLabel || <FormattedMessage id="general.button.continue" />}
          </Button>
        </div>
      </section>
      <StickyFooter className="hide-for-large" hidden={isContinueDisabled()}>
        <Button
          stickyFooter
          primary
          disabled={isContinueDisabled()}
          onClick={submitData}
        >
          {submitLabel || <FormattedMessage id="general.button.continue" />}
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

FeedOrderForm.propTypes = {
  initialValues: T.shape({
    delivery_date: T.object,
    comment: T.string,
    mentionsIds: T.arrayOf(T.number),
    feed_order_bins_attributes: T.arrayOf(T.shape({
      id: T.number,
      bin_id: T.number,
      quantity: T.number
    }))
  }),
  onSubmit: T.func.isRequired,
  binsOptions: T.arrayOf(T.shape({
    id: T.number,
    name: T.string
  })),
  backLink: T.oneOfType([T.string, T.object]).isRequired,
  title: T.node,
  submitLabel: T.node,
  farmName: T.string,
  groupName: T.string,
  router: T.object.isRequired,
};

FeedOrderForm.defaultProps = {
  initialValues: {
    delivery_date: null,
    comment: '',
    mentionsIds: [],
    feed_order_bins_attributes: [{ bin_id: null, quantity: 0 }]
  },
  binsOptions: []
};

export default withRouter(FeedOrderForm);
