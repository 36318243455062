import React, { useEffect, useState, useRef } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { showTenantNotification } from 'reducers/layout';
import { collapseSidebar } from 'reducers/sidebar';
import { closeDropdown } from 'reducers/dropdownLayout';
import { openPortal, closePortal } from 'reducers/portal';
// components
import { ScrollContainer } from 'react-router-scroll';
import Sidebar from 'components/Sidebar/Sidebar';
import MainHeader from './MainHeader';
import MainFooter from 'components/MainFooter/MainFooter';
import MobileMenuPortal from 'components/MobileMenu/MobileMenuPortal';
import MediaViewer from 'components/MediaViewer';
import ContentOverlay from 'components/ContentOverlay/ContentOverlay';
import DropdownLayout from 'components/DropdownLayout/DropdownLayout';
import ContextDropdown from 'components/ContextDropdown';
import { DropdownProvider } from 'hooks/useDropdownContext';
import TenantNotificationBox from 'components/TenantNotificationBox/TenantNotificationBox';
import NetworkConnectNotification from 'components/NetworkConnectNotification/NetworkConnectNotification';
// utils
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { isTablet, isMobile, isIOS } from 'react-device-detect';
import { formatUnviewedAssetsCount } from 'utils';
import { isEmptyFarmFeedParamsSearch } from 'utils/farmFeedHelper';
import { isFarmFeedPage } from 'utils/locationHelper';
import { isEmptyUserFilterByPathname } from 'utils/userFiltersHelper';
// hooks
import useHubspot from 'hooks/useHubspot';
// constants
import { footerItems } from './constants';
// styles
import './MainLayout.scss';

const MainLayout = ({
  children,
  closeDropdown,
  closePortal,
  collapseSidebar,
  isDropdownOpen,
  isEditMode,
  isEmptyFFParamsSearch,
  isEmptyUserFilter,
  isFullScreenMode,
  isOnline,
  isSuperAdminMode,
  isSidebarCollapsed,
  location,
  openPortal,
  showHeaderNotification,
  showTenantNotification,
  unViewedAssetsCount,
  unreadNotifications,
  user,
  router,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isContextDropdownOpened, setIsContextDropdownOpened] = useState(false);
  const [isRefGet, setIsRefGet] = useState(false);
  const bodyBagRef = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useHubspot(location.pathname);

  useEffect(() => {
    if (isTablet && !isSidebarCollapsed) collapseSidebar(true);
    if (isDropdownOpen) closeDropdown();
    if (isMobileMenuOpen) setIsMobileMenuOpen(false);
  }, [location.pathname]);

  const handleFooterClick = (item) => {
    if (isMobileMenuOpen) {
      closePortal();
      setIsMobileMenuOpen(false);
      return;
    }
    if (item.isMore) {
      openPortal(<MobileMenuPortal currentUser={user} counters={{ unViewedAssetsCount }} />, { hasFooterArea: true });
      setIsMobileMenuOpen(true);
    }
  };

  const onContentOverlayClick = () => {
    if (isDropdownOpen) closeDropdown();
    if (!isSidebarCollapsed) collapseSidebar(true);
  };

  const getBodyRef = (ref) => {
    bodyBagRef.current = ref;
    setIsRefGet(true);
  };

  const tenant = user?.current_company || {};
  const companies = user?.root_companies || [];
  const isEverypigBasic = user.current_company.everypig_basic;
  const isTenantUseLogistics = tenant.logistics;
  const isTenantUseVirtualVisits = tenant.virtual_visits;
  const isTenantUseSVR = tenant.admin_svrs;
  const isUserHasSVRLimitedAccess = user?.svrs_limited_access;
  const isTabletSidebarOpen = isTablet && !isSidebarCollapsed;
  const isMobileDropdownOpen = isMobile && !isTablet && (isDropdownOpen || isContextDropdownOpened);
  const isActiveMobileSearch = isOnline && !isDesktop && !isEmptyFFParamsSearch;
  const isActiveMobileFilters = isOnline && !isDesktop && !isEmptyUserFilter;
  const isHeaderHidden = isFullScreenMode || isActiveMobileSearch || isActiveMobileFilters;
  const isTenantUseTreatmentProtocols = user.current_company.admin_treatment_protocols
    && user.current_company.treatment_protocols;
  const isTenantUseFeedOrders = user.current_company.feed_orders_tracking;

  const getFooterItemAdditionalInfo = (itemId) => {
    switch (itemId) {
      case 'checkin':
        return { hide: !isMobile };
      case 'mySchedule':
        return { hide: !isTenantUseLogistics };
      case 'virtualVisits':
        return { hide: !isTenantUseVirtualVisits };
      case 'svr':
        return { hide: isUserHasSVRLimitedAccess || !isTenantUseSVR, isNewFeature: true };
      case 'notifications':
        return { count: unreadNotifications };
      case 'more':
        return { count: unViewedAssetsCount };
      case 'treatmentProtocols':
        return { hide: !(isEverypigBasic || isTenantUseTreatmentProtocols) };
      case 'feedOrders':
        return { hide: !isTenantUseFeedOrders };
      default:
        return undefined;
    }
  };

  const items = footerItems
    .map((item) => ({ ...item, ...getFooterItemAdditionalInfo(item.id) }))
    .filter((item) => !item.hide);

  return (
    <DropdownProvider>
      <div
        className={cn('layout-default', {
          'sidebar-collapsed': isSidebarCollapsed,
          'is-edit-mode': isEditMode,
          'is-super-admin-mode': isSuperAdminMode,
          'is-fullscreen-mode': isFullScreenMode,
          'is-tablet': isTablet,
          'with-tenant-notification': showHeaderNotification,
          'is-dropdown-open': (isDropdownOpen || isContextDropdownOpened) && isIOS,
          'has-ff-filters': isActiveMobileFilters || isActiveMobileSearch,
        })}
      >
        <TenantNotificationBox onClose={() => showTenantNotification(false)} tenant={tenant} />

        {!isHeaderHidden && (
          <MainHeader
            user={user}
            location={location}
            isTabletSidebarOpen={isTabletSidebarOpen}
          />
        )}

        <Sidebar
          user={user}
          companies={companies}
          collapseSidebar={collapseSidebar}
          location={location}
          router={router}
          isCollapsed={isSidebarCollapsed}
          isTabletSidebarOpen={isTabletSidebarOpen}
          isHidden={isEditMode || isSuperAdminMode || isFullScreenMode}
        />

        <ContentOverlay visible={isTabletSidebarOpen || isMobileDropdownOpen} onClick={onContentOverlayClick} />
        <ScrollContainer scrollKey="bodybag">
          <div id="bodybag" className="bodybag" ref={getBodyRef}>
            <NetworkConnectNotification />
            {isRefGet && bodyBagRef.current && (
            <ContextDropdown
              layoutRef={bodyBagRef.current}
              pathname={location.pathname}
              setIsOpened={(isOpened) => {
                if (isOpened !== isContextDropdownOpened) setIsContextDropdownOpened(isOpened);
              }}
            />
            )}
            <DropdownLayout />
            <div id="content-wrapper" className={cn('content-wrapper', { 'is-offline': !isOnline })}>
              {children}
            </div>
          </div>
        </ScrollContainer>

        <MainFooter
          onItemClick={handleFooterClick}
          isMobileMenuOpen={isMobileMenuOpen}
          isEditMode={isEditMode}
          items={items}
        />

        <MediaViewer />
      </div>
    </DropdownProvider>
  );
};

MainLayout.contextTypes = {
  router: T.object.isRequired,
};

MainLayout.propTypes = {
  children: T.object,
  showTenantNotification: T.func,
  user: T.object,
  isSidebarCollapsed: T.bool.isRequired,
  isDropdownOpen: T.bool.isRequired,
  isSuperAdminMode: T.bool.isRequired,
  isFullScreenMode: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  collapseSidebar: T.func.isRequired,
  closeDropdown: T.func.isRequired,
  location: T.object.isRequired,
  isEditMode: T.bool.isRequired,
  showHeaderNotification: T.bool.isRequired,
  unreadNotifications: T.number,
  unViewedAssetsCount: T.number,
  isEmptyFFParamsSearch: T.bool.isRequired,
  isEmptyUserFilter: T.bool.isRequired,
  openPortal: T.func.isRequired,
  closePortal: T.func.isRequired,
  router: T.object.isRequired,
};

export default connect(
  (state, { location: { query, pathname } }) => ({
    isSidebarCollapsed: state.sidebar.isSidebarCollapsed,
    isDropdownOpen: state.dropdownLayout.isOpen,
    isEditMode: !!state.layout.inEditMode && query.editMode === 'true',
    isSuperAdminMode: state.layout.inSuperAdminMode,
    isFullScreenMode: state.layout.isFullScreenMode,
    isOnline: state.network.isOnline,
    isEmptyFFParamsSearch: isFarmFeedPage(pathname)
      ? isEmptyFarmFeedParamsSearch(state.farmfeed.params)
      : true,
    isEmptyUserFilter: isEmptyUserFilterByPathname(state, pathname),
    showHeaderNotification: state.layout.showHeaderNotification,
    unreadNotifications: state.sidebar.counters?.unreadNotifications || 0,
    unViewedAssetsCount: formatUnviewedAssetsCount(state.sidebar.counters?.unviewedAssets || 0),
    user: state.auth.user,
  }), {
    collapseSidebar,
    showTenantNotification,
    closeDropdown,
    openPortal,
    closePortal,
  }
)(MainLayout);
