import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setTableParams } from 'reducers/tableParams';
import { openPortalRight } from 'reducers/rightToLeftPortal';
// hooks
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useMediaQuery } from 'react-responsive';
import useDidMountEffect from 'hooks/useDidMountEffect';
// endpoints
import { getFarmsForFeedOrders } from 'endpoints/feedOrders';
// components
import { FormattedMessage } from 'react-intl';
import FeedOrdersFarmRow from './components/FeedOrdersFarmRow';
import NothingBox from 'components/NothingBox';
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
// utils
import { tableNames } from 'utils/constants';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './FeedOrdersFarms.module.scss';

const cn = classnames.bind(styles);

const FeedOrdersFarms = ({
  isOnline,
  reqParams,
  router,
  setTableParams,
  setPageOptions,
  setBreadcrumbs,
}) => {
  const {
    data: {
      resources,
      isLoading,
      meta: { total },
    },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
  } = useDataTable(getFarmsForFeedOrders, {
    tableParams: reqParams,
    setTableParams: (params) => setTableParams(tableNames.feedOrderFarms, params),
  });

  const { page, per_page, search } = reqParams;
  const isDesktop = useMediaQuery({ minWidth: 1024 });


  useEffect(() => {
    fetchData({ ...reqParams, page: 1 })
      .catch(toastResponseErrors);
    setPageOptions({ backLink: '' });
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.feedOrderFarms" />,
        path: '/feed-orders-farms',
        useLabelAsMobileTitle: true
      }
    ]);
    return () => {
      setPageOptions({ rightButton: '' });
    };
  }, []);

  const renderTitle = () => (
    <h1 className="lighter">
      <FormattedMessage id="general.farms" />
    </h1>
  );

  const onFarmSelect = (farm) => {
    router.push(`/feed-orders-farms/${farm.id}/groups`);
  };

  const handleSetPageOptions = () => {
    setPageOptions({
      rightButton: '',
    });
  };

  useDidMountEffect(handleSetPageOptions, [reqParams, isDesktop, isOnline]);

  return (
    <div
      className={cn('treatment-protocols', {
        'is-desktop': isDesktop,
      })}
    >
      <div className="small-12 column row centered">
        <Panel className={cn('tp-farms-panel')}>
          <Panel.Heading renderTitle={renderTitle}>
            <Panel.Control>
              <SearchBox onChange={onSearchChange} initialValue={search} disableFocus />
            </Panel.Control>
          </Panel.Heading>
          <Panel.Body>
            <Preloader isActive={isLoading} />
            {resources.map((farm) => (
              <FeedOrdersFarmRow
                key={farm.id}
                onSelect={() => onFarmSelect(farm)}
                farm={farm}
              />
            ))}

            <NothingBox
              itemsName="farms"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
            >
              <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsToDisplay" />
            </NothingBox>

            <PaginationWrapper
              currentPage={page}
              perPage={per_page}
              onPageChange={onPageChange}
              onPerPageChange={onPerPageChange}
              totalItems={total}
              onlyBottom
            />
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
};

FeedOrdersFarms.propTypes = {
  reqParams: T.shape({
    page: T.number,
    per_page: T.number,
    search: T.string,
  }),
  router: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  isOnline: T.bool.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    reqParams: tableParamsSelector(state, tableNames.feedOrderFarms),
    isOnline: state.network.isOnline,
  }),
  {
    setPageOptions,
    setTableParams,
    openPortalRight,
    setBreadcrumbs,
  }
)(FeedOrdersFarms);
