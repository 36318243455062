import React from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import { getGroupStatus, getStatusIcon } from 'containers/DailyCheckup/components/helper';
// styles
import styles from './FeedOrdersItemRow.scss';

const cn = classnames.bind(styles);

const FeedOrdersGroupRow = ({ group, onSelect }) =>  {
  const { status } = getGroupStatus(group);

  return (
    <div className={cn('treatment-protocols-farm-row')}>
      <div className={cn('icon-section')}>
        {getStatusIcon(status)}
      </div>
      <div className={cn('name-section')}>
        <div className={cn('group-name')}>
          {group.name}
        </div>
      </div>

      <div className="vertical-divider" />
      <div className={cn('pigs-count-section')}>
        <strong>{group.pigs || 0} </strong>
        &nbsp;
        <FormattedMessage id="general.pigs" />
      </div>

      <div className={cn('action-section')}>
        <Button onClick={onSelect} className={cn('select-btn')} primary>
          <FormattedMessage id="general.button.select" />
        </Button>
      </div>
    </div>
  );
};

FeedOrdersGroupRow.propTypes = {
  group: T.object.isRequired,
  onSelect: T.func.isRequired,
};

export default FeedOrdersGroupRow;
