import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setTableParams } from 'reducers/tableParams';
// components
import { FormattedMessage } from 'react-intl';
import FeedOrdersGroupRow from './components/FeedOrdersGroupRow';
import PaginationWrapper from 'components/PaginationWrapper';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getFarmGroupsForFeedOrders } from 'endpoints/feedOrders';
// utils, constants
import { tableNames } from 'utils/constants';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const FeedOrdersFarmGroups = ({
  farm, params: { farmId }, reqParams, router, setPageOptions, setTableParams, setBreadcrumbs
}) => {
  const {
    data: {
      resources,
      isLoading,
      meta: { total },
    },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
  } = useDataTable((p) => getFarmGroupsForFeedOrders(farmId, p), {
    tableParams: reqParams,
    setTableParams: (params) => setTableParams(tableNames.feedOrderFarmGroups, params),
  });

  const { page, per_page, search } = reqParams;

  useEffect(() => {
    fetchData({ ...reqParams, page: 1 }).catch(toastResponseErrors);
    setPageOptions({ backLink: '/feed-orders-farms' });
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.feedOrders" />,
        path: '/feed-orders-farms',
      },
      {
        label: farm?.name || '',
        useLabelAsMobileTitle: true
      },
    ]);
  }, [farmId]);

  const onGroupSelect = ({ farm_id, id }) => {
    router.push(`/feed-orders-farms/${farm_id}/groups/${id}/feed-orders`);
  };

  return (
    <section className="small-12 column row centered">
      <Panel className="mb-20">
        <Panel.Heading title={<FormattedMessage id="general.pageTitle.groups" />}>
          <SearchBox onChange={onSearchChange} initialValue={search} disableFocus />
        </Panel.Heading>
        <Panel.Body className="pt-20 pb-0 ph-20">
          <Preloader isActive={isLoading} />
          {resources.map((group) => (
            <FeedOrdersGroupRow key={group.id} group={group} onSelect={() => onGroupSelect(group)} />
          ))}
          <NothingBox itemsName="groups" display={!resources.length} isLoading={isLoading}>
            <FormattedMessage tagName="h1" id="component.nothingBox.noGroupsToDisplay" />
          </NothingBox>

          <PaginationWrapper
            currentPage={page}
            perPage={per_page}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            totalItems={total}
            onlyBottom
          />
        </Panel.Body>
      </Panel>
    </section>
  );
};

FeedOrdersFarmGroups.propTypes = {
  reqParams: T.object.isRequired,
  params: T.object.isRequired,
  farm: T.object.isRequired,
  router: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    reqParams: tableParamsSelector(state, tableNames.feedOrderFarmGroups),
    farm: state.profiles.farm.data,
  }), {
    setPageOptions,
    setTableParams,
    setBreadcrumbs,
  }
)(FeedOrdersFarmGroups);
