import { fetchFromAPI } from 'utils/api';

export const getFarmsForFeedOrders = (params) => {
  return fetchFromAPI('/feed_orders/farms', { params });
};

export const getFeedOrdersFarm = (farmId) => {
  return fetchFromAPI(`/feed_orders/farms/${farmId}`).then((response) => response.resource);
};

export const getFarmGroupsForFeedOrders = (farmId, params) => {
  return fetchFromAPI(`/feed_orders/farms/${farmId}/pig_groups`, { params });
};

export const getFeedOrdersFarmPigGroup = (farmId, pigGroupId) => {
  return fetchFromAPI(`/feed_orders/farms/${farmId}/pig_groups/${pigGroupId}`)
    .then((response) => response.resource);
};

export const createFeedOrder = (pigGroupId, resource) => {
  return fetchFromAPI(
    `/pig_groups/${pigGroupId}/feed_orders`,
    { method: 'POST', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};
