import React from 'react';
import T from 'prop-types';
// components
import FarmTypeLabel from 'components/FarmTypeLabel';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import DotSeparator from 'components/DotSeparator/DotSeparator';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './FeedOrdersItemRow.scss';

const cn = classnames.bind(styles);

const getStatusIcon = (status) => {
  switch (status) {
    case 'active':
      return <div className={cn('red-marker')} />;
    case 'disabled':
      return <div className={cn('fa fa-lock', 'status-icon')} />;
    default:
      return null;
  }
};

const FeedOrdersFarmRow = ({ farm, onSelect }) =>  (
  <div className={cn('treatment-protocols-farm-row')}>
    <div className={cn('icon-section')}>
      {getStatusIcon(farm.status)}
    </div>
    <div className={cn('name-section')}>
      <span className={cn('farm-name')}>
        {farm.name}
        <FarmTypeLabel className="ml-10" farmType={farm.farm_type} />
      </span>
      <span className={cn('farm-manager')}>
        {farm.manager?.name || <FormattedMessage id="general.notAvail" />}
      </span>
    </div>
    <div className={cn('action-section')}>
      <Button onClick={onSelect} className={cn('select-btn')} primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    </div>
  </div>
);

FeedOrdersFarmRow.propTypes = {
  farm: T.object.isRequired,
  onSelect: T.func.isRequired,
};

export default FeedOrdersFarmRow;
