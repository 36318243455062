import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// redux
import { connect } from 'react-redux';
// styles
import './SpeechToText.scss';

const SpeechToText = ({
  setTextMain,
  setTextInterim,
  currentUser,
  className = '',
  buttonClassName = '',
  iconClassName = '',
}) => {
  const socketRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const audioContextRef = useRef(null);
  const mediaStreamSourceRef = useRef(null);
  const processorRef = useRef(null);
  const streamRef = useRef(null);

  const languageMap = {
    en: 'en-US',
    'es-CO': 'es-CO',
    ko: 'ko-KR',
    'pt-BR': 'pt-BR',
    fr: 'fr-FR',
    vi: 'vi-VN',
    'zh-CN': 'zh-CN',
    th: 'th-TH',
  };
  const languageCode = languageMap[currentUser.language] || 'en-US';
  const userIdStr = String(currentUser.id);

  function convertFloat32ToInt16(buffer) {
    const l = buffer.length;
    const int16Buffer = new Int16Array(l);
    for (let i = 0; i < l; i++) {
      const s = Math.max(-1, Math.min(1, buffer[i]));
      int16Buffer[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
      int16Buffer[i] = Math.round(int16Buffer[i]);
    }
    return int16Buffer;
  }

  function bufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer.buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const startRecording = () => {
    // eslint-disable-next-line no-console
    console.log('Starting recording');
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((userStream) => {
          streamRef.current = userStream;
          audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
            sampleRate: 16000,
          });
          mediaStreamSourceRef.current = audioContextRef.current.createMediaStreamSource(
            streamRef.current
          );
          processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);

          if (window.actionCable) {
            if (socketRef.current) {
              socketRef.current.unsubscribe();
            }
            socketRef.current = window.actionCable.subscriptions.create(
              { channel: 'SpeechChannel', user_id: userIdStr, language_code: languageCode },
              {
                received: (data) => {
                  if (data.error) {
                    // eslint-disable-next-line no-console
                    console.error('Error from server:', data.error);
                  } else if (data.transcript) {

                    if (data.is_final) {
                      setTextMain((prev) => `${prev || ''} ${data.transcript}`.trim());
                      setTextInterim('');
                    } else {
                      setTextInterim(data.transcript);
                    }
                  }
                },
              }
            );
          }

          processorRef.current.onaudioprocess = (event) => {
            const audioBuffer = event.inputBuffer.getChannelData(0);
            const int16Array = convertFloat32ToInt16(audioBuffer);
            const base64Audio = bufferToBase64(int16Array);

            if (socketRef.current) {
              socketRef.current.send({ audio: base64Audio });
            }
          };

          mediaStreamSourceRef.current.connect(processorRef.current);
          processorRef.current.connect(audioContextRef.current.destination);
          setIsRecording(true);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error accessing microphone:', error);
        });
    }
  };

  const stopRecording = () => {
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current.onaudioprocess = null;
      processorRef.current = null;
    }
    if (mediaStreamSourceRef.current) {
      mediaStreamSourceRef.current.disconnect();
      mediaStreamSourceRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (socketRef.current) {
      socketRef.current.unsubscribe();
      socketRef.current = null;
    }
    setIsRecording(false);
  };

  useEffect(() => {
    return () => {
      stopRecording();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`speech-to-text ${className}`}>
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className={`control-button main-control ${isRecording ? 'secondary' : 'primary'} ${buttonClassName}`}
        aria-label={isRecording ? 'Stop Recording' : 'Start Recording'}
      >
        <i className={`fa ${isRecording ? 'fa-stop' : 'fa-mic'} ${iconClassName}`} />
      </button>
    </div>
  );
};

SpeechToText.propTypes = {
  setTextMain: PropTypes.func.isRequired,
  setTextInterim: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default connect((state) => ({ currentUser: state.auth.user }))(SpeechToText);
