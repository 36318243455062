import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchProfile } from 'reducers/profiles';
// components
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
// endpoints
import { getFeedOrdersFarmPigGroup } from 'endpoints/feedOrders';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const FeedOrdersGroup = ({
  fetchProfile,
  children,
  isLoading,
  params: {
    groupId,
    farmId,
  },
  pigGroup,
  router,
}) => {
  useEffect(() => {
    fetchProfile('pigGroup', () => {
      return getFeedOrdersFarmPigGroup(farmId, groupId).catch((error) => {
        toastResponseErrors(error);
        router.push('/feed-orders-farms');
      });
    });
  }, [farmId, groupId]);

  return (
    <div>
      <GroupProfileHeader group={pigGroup} isLoading={isLoading} />
      {children}
    </div>
  );
};

FeedOrdersGroup.propTypes = {
  pigGroup: T.object.isRequired,
  fetchProfile: T.func.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isLoading: T.bool.isRequired,
  params: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.profiles.pigGroup.data,
    isLoading: state.profiles.pigGroup.isLoading,
  }),
  { fetchProfile }
)(FeedOrdersGroup);
