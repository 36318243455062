import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
// endpoints
import { getFeedOrdersFarm } from 'endpoints/feedOrders';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

function isFeedOrdersPages(path) {
  const pathParts = path.split('/');
  return ['create', 'edit'].includes(pathParts[pathParts.length - 1]);
}

const FeedOrdersFarm = ({
  farm,
  fetchProfile,
  children,
  isLoading,
  location: {
    pathname,
  },
  params: { farmId },
  router,
}) => {
  const { open_pig_groups_count, pigs_count, federal_premise_id } = farm;
  const isStickyHeaderAvailable = !isFeedOrdersPages(pathname);

  useEffect(() => {
    const fetchFarm = () => {
      return getFeedOrdersFarm(farmId).catch((error) => {
        toastResponseErrors(error);
        router.push('/feed-orders-farms');
      });
    };
    fetchProfile('farm', fetchFarm);
  }, [farmId]);

  return (
    <div>
      <FarmProfileHeader
        className="show-for-large"
        farm={farm}
        isLoading={isLoading}
        actualFarmId={Number(farmId)}
        farmInfo={(
          <div className="color-gray-dark">
            <span>ID&nbsp;&bull;&nbsp;{federal_premise_id || 'N/A'}</span>
          </div>
        )}
        stats={[
          {
            label: <FormattedMessage id="general.openGroups" />,
            value: open_pig_groups_count || 0,
          },
          {
            label: <FormattedMessage id="general.puc" />,
            value: pigs_count || 0,
          },
        ]}
      />
      {children}
    </div>
  );
};

FeedOrdersFarm.propTypes = {
  farm: T.object.isRequired,
  fetchProfile: T.func.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isLoading: T.bool.isRequired,
  params: T.object.isRequired,
  location: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    farm: state.profiles.farm.data,
    isLoading: state.profiles.farm.isLoading,
  }), { fetchProfile }
)(FeedOrdersFarm);
